import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import '../util/global'//全局
import signalR from "../util/signalR";
import './promissionRouter'//这里进行路由后台获取的模拟
import ElementUI from 'element-ui'
import Cookies from 'js-cookie'

import 'element-ui/lib/theme-chalk/index.css';
import 'font-awesome/css/font-awesome.min.css'

Vue.use(ElementUI, {
  size: Cookies.get('size') || 'medium'
});
Vue.prototype.signalR = signalR;
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }

  if (to.path === '/login') { // 如果是登录页面路径，就直接next()
    next()
  } else { // 其他页面路径
    var curTime = new Date();
    if (window.localStorage.TokenExpire) {
      var expiretime = new Date(Date.parse(window.localStorage.TokenExpire));
      if (curTime >= expiretime) {
        next({ path: '/login' }) // 则跳转到登录页
      } else {
        next()
      }
    } else {
      next({ path: '/login' }) // 则跳转到登录页
    }
  }

})

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
