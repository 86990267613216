import axios from 'axios';
import router from '../router/index'
import store from "../store";
import Vue from 'vue';
let base = '';
// 如果是IIS部署，用这个，因为 IIS 只能是 CORS 跨域，不能代理
// let base = process.env.NODE_ENV=="production"? 'http://localhost:8081':'';


// 请求延时
axios.defaults.timeout = 100000

var storeTemp = store;
axios.interceptors.request.use(
    config => {
        var curTime = new Date()
        var expiretime = new Date(Date.parse(storeTemp.state.tokenExpire))

        if (storeTemp.state.token && (curTime < expiretime && storeTemp.state.tokenExpire)) {
            // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.Authorization = "Bearer " + storeTemp.state.token;
        }

        saveRefreshtime();

        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// http response 拦截器
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        let errInfo = { success: false, message: "错误" }
        // 超时请求处理
        var originalRequest = error.config;
        if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1 && !originalRequest._retry) {
            errInfo.message = "请求超时！";
            originalRequest._retry = true
        } else if (error.response) {
            if (error.response.status == 401) {
                var curTime = new Date()
                var refreshtime = new Date(Date.parse(window.localStorage.refreshtime))
                // 在用户操作的活跃期内
                if (window.localStorage.refreshtime && (curTime <= refreshtime)) {
                    return refreshToken({ token: window.localStorage.Token }).then((res) => {
                        if (res.success) {
                            Vue.prototype.$message({
                                message: 'refreshToken success! loading data...',
                                type: 'success'
                            });

                            store.commit("saveToken", res.response.token);

                            var curTime = new Date();
                            var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + res.response.expires_in));
                            store.commit("saveTokenExpire", expiredate);

                            error.config.__isRetryRequest = true;
                            error.config.headers.Authorization = 'Bearer ' + res.response.token;
                            return axios(error.config);
                        } else {
                            // 刷新token失败 清除token信息并跳转到登录页面
                            ToLogin()
                        }
                    });
                } else {
                    // 返回 401，并且不知用户操作活跃期内 清除token信息并跳转到登录页面
                    ToLogin()
                }

            }
            // 403 无权限
            else if (error.response.status == 403) {
                errInfo.message = "失败！该操作无权限";
            }
            // 429 ip限流
            else if (error.response.status == 429) {
                errInfo.message = "刷新次数过多，请稍事休息重试！";
            } else if (error.response.status == 404) {
                // 404 不存在
                errInfo.message = "失败！访问接口不存在";
            } else if (error.response.status == 500) {
                // 500 服务器异常
                errInfo.message = "失败！服务器异常";
            } else if (error.response.status == 405) {
                // 405 请求http方法错误
                errInfo.message = "失败！请求http方法错误";
            } else if (error.response.status == 415) {
                // 415 参数没有指定Body还是Query
                errInfo.message = "失败！参数没有指定Body还是Query";
            } else {
                //其他错误参数
                errInfo.message = '失败！请求错误' + error.response.status;
            }
        } else {
            errInfo.message = "失败！服务器断开";
        }
        Vue.prototype.$message({
            message: errInfo.message,
            type: 'error'
        });
        ToLogin();
        return errInfo; // 返回接口返回的错误信息
    }
);


export const BaseApiUrl = base;

// 登录
export const requestLogin = params => {
    return axios.post(`${base}/api/login/GetJwtToken`, params).then(res => res.data);
};
export const requestLoginMock = params => { return axios.post(`${base}/login`, params).then(res => res.data); };

export const refreshToken = params => {
    return axios.get(`${base}/api/login/RefreshToken`, params).then(res => res.data);
};

export const saveRefreshtime = () => {

    let nowtime = new Date();
    let lastRefreshtime = window.localStorage.refreshtime ? new Date(window.localStorage.refreshtime) : new Date(-1);
    let expiretime = new Date(Date.parse(window.localStorage.TokenExpire))

    let refreshCount = 1;//滑动系数
    if (lastRefreshtime >= nowtime) {
        lastRefreshtime = nowtime > expiretime ? nowtime : expiretime;
        lastRefreshtime.setMinutes(lastRefreshtime.getMinutes() + refreshCount);
        window.localStorage.refreshtime = lastRefreshtime;
    } else {
        window.localStorage.refreshtime = new Date(-1);
    }
};
const ToLogin = () => {
    store.commit("saveToken", "");
    store.commit("saveTokenExpire", "");
    store.commit("saveTagsData", "");
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('NavigationBar');
    if (router.history.current.path != "/login")
        router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath }
        });
};

export const getUserByToken = () => {
    return axios.post(`${base}/api/Tools/GetUserInfo`).then(res => res.data);
};

export const getNavigationBar = () => {
    return axios.post(`${base}/api/Tools/GetNavigationBar`).then(res => res.data);
};


export const getAllControllerAndActionByAssembly = () => {
    return axios.get(`${base}/api/Tools/GetAllControllerAndActionByAssembly`).then(res => res.data);
};

export const getPermissionTreeTable = () => {
    return axios.get(`${base}/api/SystemMenu/GetPageList`).then(res => res.data);
};
export const getModuleListPage = () => {
    return axios.get(`${base}/api/Tools/GetAllApi`).then(res => res.data);
};
export const getPermissionTree = params => {
    return axios.get(`${base}/api/SystemMenu/GetSysMenu`, { params: params });
};
export const removePermission = params => {
    return axios.delete(`${base}/api/SystemMenu/DoDelete`, { params: params });
};
export const editPermission = params => {
    return axios.post(`${base}/api/SystemMenu/DoEdit`, params);
};
export const addPermission = params => {
    return axios.post(`${base}/api/SystemMenu/DoCreate`, params);
};
export const batchImport = params => {
    return axios.post(`${base}/api/SystemMenu/BatchImport`, params).then(res => res.data);
};
export const setAssign = params => {
    return axios.post(`${base}/api/SystemRole/DoSetSysMenu`, params).then(res => res.data);
};
export const getRoleListPage = params => {
    return axios.get(`${base}/api/SystemRole/GetPageList`, { params: params });
};
export const getRoleSet = params => {
    return axios.get(`${base}/api/SystemRole/GetRoleSet`, { params: params }).then(res => res.data);
};
export const editRole = params => {
    return axios.post(`${base}/api/SystemRole/DoEdit`, params).then(res => res.data);
};
export const addRole = params => {
    return axios.post(`${base}/api/SystemRole/DoCreate`, params).then(res => res.data);
};
export const removeRole = params => {
    return axios.post(`${base}/api/SystemRole/DoDelete`, params).then(res => res.data);
};
export const getUserListPage = params => {
    return axios.get(`${base}/api/SystemUser/GetPageList`, { params: params });
};
export const getRoles = () => {
    return axios.get(`${base}/api/SystemUser/GetRoles`);
};
export const editUser = params => {
    return axios.post(`${base}/api/SystemUser/DoEdit`, params).then(res => res.data);
};
export const addUser = params => {
    return axios.post(`${base}/api/SystemUser/DoCreate`, params).then(res => res.data);
};
export const removeUser = params => {
    return axios.post(`${base}/api/SystemUser/DoDelete`, params).then(res => res.data);
};

//************用户管理************
//获取用户列表
export const getCoreCmsUserListPage = params => {
    return axios.get(`${base}/api/CoreCmsUser/GetPageList`, { params: params });
};
//编辑用户信息
export const editCoreCmsUser = params => {
    return axios.post(`${base}/api/CoreCmsUser/DoEdit`, params).then(res => res.data);
};
//删除用户
export const removeCoreCmsUser = params => {
    return axios.post(`${base}/api/CoreCmsUser/DoDelete`, params).then(res => res.data);
};
//用户充值
export const rechargeCoreCmsUser = params => {
    return axios.post(`${base}/api/CoreCmsUser/DoEditBalance`, params).then(res => res.data);
};
//获取银行卡信息
export const getUserBankInfo = params => {
    return axios.post(`${base}/api/CoreCmsUser/GetBankInfo`, params).then(res => res.data);
};
//编辑银行卡信息
export const editUserBankInfo = params => {
    return axios.post(`${base}/api/CoreCmsUser/DoEditBankInfo`, params).then(res => res.data);
};
//导出表格
export const exportCoreCmsUser = params => {
    return axios.post(`${base}/api/CoreCmsUser/DoExportExcel`, params).then(res => res.data);
};

//用户余额日志
export const getCoreCmsUserBanlanceLogListPage = params => {
    return axios.post(`${base}/api/CoreCmsUser/GetBanlanceLogListPage`, params).then(res => res.data);
};


//************店铺管理************
//获取店铺列表
export const getStoreListPage = params => {
    return axios.get(`${base}/api/CoreCmsStore/GetPageList`, { params: params });
};
//店铺充值
export const rechargeStore = params => {
    return axios.post(`${base}/api/CoreCmsStore/DoEditBalance`, params).then(res => res.data);
};
//修改店铺业务员
export const doEditOperater = params => {
    return axios.post(`${base}/api/CoreCmsStore/DoEditOperater`, params).then(res => res.data);
};
//获取店铺分类列表
export const getStoreCategoryTree = params => {
    return axios.get(`${base}/api/CoreCmsStoreCategory/GetTree`, { params: params });
};
//新增店铺信息
export const addStore = params => {
    return axios.post(`${base}/api/CoreCmsStore/DoAdd`, params).then(res => res.data);
};
//获取店铺信息
export const getStore = params => {
    return axios.post(`${base}/api/CoreCmsStore/GetEdit`, params).then(res => res.data);
};
//编辑店铺信息
export const editStore = params => {
    return axios.post(`${base}/api/CoreCmsStore/DoEdit`, params).then(res => res.data);
};
//上架店铺
export const putOnStore = params => {
    return axios.post(`${base}/api/CoreCmsStore/DoPutOn`, params).then(res => res.data);
};
//下架店铺
export const putOffStore = params => {
    return axios.post(`${base}/api/CoreCmsStore/DoPutOff`, params).then(res => res.data);
};
//删除店铺
export const removeStore = params => {
    return axios.post(`${base}/api/CoreCmsStore/DoDelete`, params).then(res => res.data);
};
//导出表格
export const exportStoreList = params => {
    return axios.get(`${base}/api/CoreCmsStore/DoExportExcel`, { params: params }).then(res => res.data);
};

//获取店铺列表
export const getStoreSelectList = params => {
    return axios.get(`${base}/api/CoreCmsStore/GetSelectList`, { params: params }).then(res => res.data);
};

//获取店铺商品树
export const getStoreGoodsTree = params => {
    return axios.post(`${base}/api/CoreCmsStore/GetStoreGoodsTree`, params).then(res => res.data);
};

//获取超市已选服务
export const getSelectedGoods = params => {
    return axios.get(`${base}/api/CoreCmsStore/GetSelectedGoods`, { params: params }).then(res => res.data);
};
//提交超市已选服务
export const setSelectedGoods = params => {
    return axios.post(`${base}/api/CoreCmsStore/DoUpdateService`, params).then(res => res.data);
};

//获取店铺分类列表
export const getStoreCategoryPageTree = params => {
    return axios.get(`${base}/api/CoreCmsStoreCategory/GetPageTree`, { params: params }).then(res => res.data);
};
//新增店铺分类信息
export const addStoreCategory = params => {
    return axios.post(`${base}/api/CoreCmsStoreCategory/DoAdd`, params).then(res => res.data);
};
//编辑店铺分类数据
export const getStoreCategory = params => {
    return axios.post(`${base}/api/CoreCmsStoreCategory/GetEdit`, params).then(res => res.data);
};
//编辑店铺分类信息
export const editStoreCategory = params => {
    return axios.post(`${base}/api/CoreCmsStoreCategory/DoEdit`, params).then(res => res.data);
};
//删除店铺分类
export const removeStoreCategory = params => {
    return axios.post(`${base}/api/CoreCmsStoreCategory/DoDelete`, params).then(res => res.data);
};


//店铺申请列表
//获取店铺申请列表
export const getStoreApplyPageList = params => {
    return axios.get(`${base}/api/CoreCmsStoreApply/GetPageList`, { params: params }).then(res => res.data);
};
//店铺申请获取详情
export const getEdit = params => {
    return axios.post(`${base}/api/CoreCmsStoreApply/GetEdit`, params).then(res => res.data);
};
//删除店铺申请
export const removeStoreApply = params => {
    return axios.post(`${base}/api/CoreCmsStoreApply/DoDelete`, params).then(res => res.data);
};
//处理店铺申请
export const dealStoreApply = params => {
    return axios.post(`${base}/api/CoreCmsStoreApply/DoUpdateState`, params).then(res => res.data);
};
//一键生成店铺
export const quickCreateStore = params => {
    return axios.post(`${base}/api/CoreCmsStoreApply/DoQuickCreate`, params).then(res => res.data);
};
//导出表格
export const exportStoreApplyList = params => {
    return axios.get(`${base}/api/CoreCmsStoreApply/DoExportExcel`, { params: params }).then(res => res.data);
};
//获取店铺邀请列表
export const getStoreInvitePageList = params => {
    return axios.get(`${base}/api/StoreInviteApply/GetPageList`, { params: params }).then(res => res.data);
};
//店长列表
//获取店长列表
export const getStoreAdminPageList = params => {
    return axios.get(`${base}/api/CoreCmsStoreAdmin/GetPageList`, { params: params }).then(res => res.data);
};
//导出表格
export const exportStoreAdminList = params => {
    return axios.get(`${base}/api/CoreCmsStoreAdmin/DoExportExcel`, { params: params });
};
//导出表格
export const exportStoreInviteExcel = params => {
    return axios.get(`${base}/api/StoreInviteApply/DoExportExcel`, { params: params });
};




//************服务管理************

//获取服务列表
export const getServicesListPage = params => {
    return axios.get(`${base}/api/CoreCmsServices/GetPageList`, { params: params });
};
//新增服务信息
export const addServices = params => {
    return axios.post(`${base}/api/CoreCmsServices/DoAdd`, params).then(res => res.data);
};
//获取服务信息
export const getServices = params => {
    return axios.post(`${base}/api/CoreCmsServices/GetEdit`, params).then(res => res.data);
};
//编辑服务信息
export const editServices = params => {
    return axios.post(`${base}/api/CoreCmsServices/DoEdit`, params).then(res => res.data);
};
//上架服务
export const putOnServices = params => {
    return axios.post(`${base}/api/CoreCmsServices/DoPutOn`, params).then(res => res.data);
};
//下架服务
export const putOffServices = params => {
    return axios.post(`${base}/api/CoreCmsServices/DoPutOff`, params).then(res => res.data);
};
//删除服务
export const removeServices = params => {
    return axios.post(`${base}/api/CoreCmsServices/DoDelete`, params).then(res => res.data);
};
//导出服务
export const exportServicesList = params => {
    return axios.post(`${base}/api/CoreCmsServices/DoExportExcel`, params).then(res => res.data);
};


//获取服务分类树
export const getServicesCategoryTree = params => {
    return axios.get(`${base}/api/CoreCmsServicesCategory/GetTree`, { params: params });
};
//获取服务分类树列表
export const getServicesCategoryPageTree = params => {
    return axios.get(`${base}/api/CoreCmsServicesCategory/GetPageTree`, { params: params }).then(res => res.data);
};
//新增服务分类信息
export const addServicesCategory = params => {
    return axios.post(`${base}/api/CoreCmsServicesCategory/DoAdd`, params).then(res => res.data);
};
//编辑服务分类数据
export const getServicesCategory = params => {
    return axios.post(`${base}/api/CoreCmsServicesCategory/GetEdit`, params).then(res => res.data);
};
//编辑服务分类信息
export const editServicesCategory = params => {
    return axios.post(`${base}/api/CoreCmsServicesCategory/DoEdit`, params).then(res => res.data);
};
//删除服务分类
export const removeServicesCategory = params => {
    return axios.post(`${base}/api/CoreCmsServicesCategory/DoDelete`, params).then(res => res.data);
};






//************商品管理************

//获取商品列表
export const getGoodsListPage = params => {
    return axios.get(`${base}/api/CoreCmsGoods/GetPageList`, { params: params });
};
//新增数据获取
export const getAdd = params => {
    return axios.post(`${base}/api/CoreCmsGoods/GetAdd`, params).then(res => res.data);
};
//新增商品信息
export const addGoods = params => {
    return axios.post(`${base}/api/CoreCmsGoods/DoAdd`, params).then(res => res.data);
};
//获取商品信息
export const getGoods = params => {
    return axios.post(`${base}/api/CoreCmsGoods/GetEdit`, params).then(res => res.data);
};
//编辑商品信息
export const editGoods = params => {
    return axios.post(`${base}/api/CoreCmsGoods/DoEdit`, params).then(res => res.data);
};
//上架商品
export const putOnGoods = params => {
    return axios.post(`${base}/api/CoreCmsGoods/DoPutOn`, params).then(res => res.data);
};
//下架商品
export const putOffGoods = params => {
    return axios.post(`${base}/api/CoreCmsGoods/DoPutOff`, params).then(res => res.data);
};
//删除商品
export const removeGoods = params => {
    return axios.post(`${base}/api/CoreCmsGoods/DoDelete`, params).then(res => res.data);
};
//导出表格
export const exportGoodsList = params => {
    return axios.post(`${base}/api/CoreCmsGoods/DoExportExcel`, params).then(res => res.data);
};



//获取商品分类树
export const getGoodsCategoryTree = params => {
    return axios.get(`${base}/api/CoreCmsGoodsCategory/GetTree`, { params: params });
};
//获取商品分类树列表
export const getGoodsCategoryPageTree = params => {
    return axios.get(`${base}/api/CoreCmsGoodsCategory/GetPageTree`, { params: params }).then(res => res.data);
};
//新增商品分类信息
export const addGoodsCategory = params => {
    return axios.post(`${base}/api/CoreCmsGoodsCategory/DoAdd`, params).then(res => res.data);
};
//编辑商品分类数据
export const getGoodsCategory = params => {
    return axios.post(`${base}/api/CoreCmsGoodsCategory/GetEdit`, params).then(res => res.data);
};
//编辑商品分类信息
export const editGoodsCategory = params => {
    return axios.post(`${base}/api/CoreCmsGoodsCategory/DoEdit`, params).then(res => res.data);
};
//删除商品分类
export const removeGoodsCategory = params => {
    return axios.post(`${base}/api/CoreCmsGoodsCategory/DoDelete`, params).then(res => res.data);
};


//************轮播管理************
//获取轮播列表
export const getBannerListPage = params => {
    return axios.get(`${base}/api/CoreCmsBanner/GetPageList`, { params: params });
};
//新增轮播信息
export const addBanner = params => {
    return axios.post(`${base}/api/CoreCmsBanner/DoAdd`, params).then(res => res.data);
};
//获取轮播位置
export const getBannerLocation = params => {
    return axios.post(`${base}/api/CoreCmsBanner/GetLocation`, params).then(res => res.data);
};
//获取轮播数据
export const getBanner = params => {
    return axios.post(`${base}/api/CoreCmsBanner/GetEdit`, params).then(res => res.data);
};
//编辑轮播信息
export const editBanner = params => {
    return axios.post(`${base}/api/CoreCmsBanner/DoEdit`, params).then(res => res.data);
};
//显示轮播
export const updateBannerState = params => {
    return axios.post(`${base}/api/CoreCmsBanner/DoUpdateState`, params).then(res => res.data);
};
//删除轮播
export const removeBanner = params => {
    return axios.post(`${base}/api/CoreCmsBanner/DoDelete`, params).then(res => res.data);
};


//************订单管理************
//获取订单列表
export const getOrderListPage = params => {
    return axios.get(`${base}/api/CoreCmsOrder/GetPageList`, { params: params });
};
//导出表格
export const exportOrdereList = params => {
    return axios.get(`${base}/api/CoreCmsOrder/DoExportExcel`, { params: params });
};

//导入数据
export const importOrder = params => {
    return axios.post(`${base}/api/CoreCmsOrder/ImportOrder`, params).then(res => res.data);
};



//************接龙管理************
//获取接龙列表
export const getJieLongListPage = params => {
    return axios.get(`${base}/api/CoreCmsJieLong/GetPageList`, { params: params });
};
//导出表格
export const exportJieLong = params => {
    return axios.post(`${base}/api/CoreCmsJieLong/DoExportExcel`, params).then(res => res.data);
};

//获取接龙模板列表
export const getJieLongTemplateListPage = params => {
    return axios.get(`${base}/api/JieLongTemplate/GetPageList`, { params: params });
};
//新增接龙模板信息
export const addJieLongTemplate = params => {
    return axios.post(`${base}/api/JieLongTemplate/DoAdd`, params).then(res => res.data);
};
//获取接龙模板数据
export const getJieLongTemplate = params => {
    return axios.post(`${base}/api/JieLongTemplate/GetEdit`, params).then(res => res.data);
};
//编辑接龙模板信息
export const editJieLongTemplate = params => {
    return axios.post(`${base}/api/JieLongTemplate/DoEdit`, params).then(res => res.data);
};
//置顶接龙模板
export const updateJieLongTemplateState = params => {
    return axios.post(`${base}/api/JieLongTemplate/DoUpdateState`, params).then(res => res.data);
};
//删除接龙模板
export const removeJieLongTemplate = params => {
    return axios.post(`${base}/api/JieLongTemplate/DoDelete`, params).then(res => res.data);
};




//************报名管理************
//获取保险报名列表
export const getEnrollListPage = params => {
    return axios.get(`${base}/api/CoreCmsEnroll/GetPageList`, { params: params });
};
//处理保险报名
export const dealEnrol = params => {
    return axios.post(`${base}/api/CoreCmsEnroll/DoDeal`, params).then(res => res.data);
};
//返利
export const rebateEnrol = params => {
    return axios.post(`${base}/api/CoreCmsEnroll/DoRebate`, params).then(res => res.data);
};
//删除报名
export const removeEnrol = params => {
    return axios.post(`${base}/api/CoreCmsEnroll/DoDelete`, params).then(res => res.data);
};






//************财务管理************
//获取提现列表
export const getWithdrawalListPage = params => {
    return axios.get(`${base}/api/CoreCmsWithdrawal/GetPageList`, { params: params });
};
//提现成功
export const updateWithdrawalSuccess = params => {
    return axios.post(`${base}/api/CoreCmsWithdrawal/DoUpdateSuccess`, params).then(res => res.data);
};
//提现失败
export const updateWithdrawalFail = params => {
    return axios.post(`${base}/api/CoreCmsWithdrawal/DoUpdateFail`, params).then(res => res.data);
};
//删除提现申请
export const removeWithdrawal = params => {
    return axios.post(`${base}/api/CoreCmsWithdrawal/DoDelete`, params).then(res => res.data);
};

//交易流水
export const getBillPaymentsListPage = params => {
    return axios.get(`${base}/api/BillPayments/GetPageList`, { params: params });
};

//获取财务表格数据
export const getStatisticsData = params => {
    return axios.get(`${base}/api/Statistics/GetStatisticsData`, { params: params });
};




//************文章管理************

//获取文章列表
export const getArticleListPage = params => {
    return axios.get(`${base}/api/CoreCmsArticle/GetPageList`, { params: params });
};
//新增文章信息
export const addArticle = params => {
    return axios.post(`${base}/api/CoreCmsArticle/DoAdd`, params).then(res => res.data);
};
//获取文章信息
export const getArticle = params => {
    return axios.post(`${base}/api/CoreCmsArticle/GetEdit`, params).then(res => res.data);
};
//编辑文章信息
export const editArticle = params => {
    return axios.post(`${base}/api/CoreCmsArticle/DoEdit`, params).then(res => res.data);
};
//上架文章
export const putOnArticle = params => {
    return axios.post(`${base}/api/CoreCmsArticle/DoPutOn`, params).then(res => res.data);
};
//下架文章
export const putOffArticle = params => {
    return axios.post(`${base}/api/CoreCmsArticle/DoPutOff`, params).then(res => res.data);
};
//删除文章
export const removeArticle = params => {
    return axios.post(`${base}/api/CoreCmsArticle/DoDelete`, params).then(res => res.data);
};


//获取文章分类树
export const getArticleTypeTree = params => {
    return axios.get(`${base}/api/CoreCmsArticleType/GetTree`, { params: params });
};
//获取文章分类树列表
export const getArticleTypePageTree = params => {
    return axios.get(`${base}/api/CoreCmsArticleType/GetPageTree`, { params: params }).then(res => res.data);
};
//新增文章分类信息
export const addArticleType = params => {
    return axios.post(`${base}/api/CoreCmsArticleType/DoAdd`, params).then(res => res.data);
};
//编辑文章分类数据
export const getArticleType = params => {
    return axios.post(`${base}/api/CoreCmsArticleType/GetEdit`, params).then(res => res.data);
};
//编辑文章分类信息
export const editArticleType = params => {
    return axios.post(`${base}/api/CoreCmsArticleType/DoEdit`, params).then(res => res.data);
};
//删除文章分类
export const removeArticleType = params => {
    return axios.post(`${base}/api/CoreCmsArticleType/DoDelete`, params).then(res => res.data);
};



//配送管理
//获取待分配订单列表
export const getToDivideOrderListPage = params => {
    return axios.get(`${base}/api/ToDivideOrder/GetPageList`, { params: params });
};
//生成配送单
export const createDeliverOrder = params => {
    return axios.post(`${base}/api/ToDivideOrder/CreateDeliverOrder`, params).then(res => res.data);
};


//获取配送单列表
export const getDeliverOrderListPage = params => {
    return axios.get(`${base}/api/DeliverOrder/GetPageList`, { params: params });
};
//获取配送员列表
export const getDeliverClerkListPage = params => {
    return axios.get(`${base}/api/DeliverClerk/GetPageList`, { params: params });
};
//获取配送员列表
export const getDeliverClerkSelectList = params => {
    return axios.get(`${base}/api/DeliverClerk/GetSelectList`, { params: params });
};
//获取配送单信息
export const getDeliverOrderEdit = params => {
    return axios.post(`${base}/api/DeliverOrder/GetEdit`, params).then(res => res.data);
};
//编辑配送单信息
export const editDeliverOrder = params => {
    return axios.post(`${base}/api/DeliverOrder/DoEdit`, params).then(res => res.data);
};
//获取配送单商品信息
export const getDeliverOrderItemList = params => {
    return axios.post(`${base}/api/DeliverOrder/GetDetails`, params).then(res => res.data);
};

//导出配送单(超市)
export const getDeliverOrderToStore = params => {
    return axios.get(`${base}/api/DeliverOrder/DoExportExcelToStore`, { params: params }).then(res => res.data);
};

//导出配送单列表
export const exportDeliverOrderList = params => {
    return axios.get(`${base}/api/DeliverOrder/DoExportExcel`, { params: params }).then(res => res.data);
};

//获取配送员配送单列表
export const getDeliverOrderList = params => {
    return axios.post(`${base}/api/DeliverClerk/GetDetails`, params).then(res => res.data);
};

//导出数据统计
export const exportStatistics = params => {
    return axios.get(`${base}/api/Statistics/DoExportExcel`, { params: params }).then(res => res.data);
};

//获取配送员申请列表
export const getDeliverClerkApplyListPage = params => {
    return axios.get(`${base}/api/DeliverClerkApply/GetPageList`, { params: params });
};
//审核通过
export const auditSuccess = params => {
    return axios.post(`${base}/api/DeliverClerkApply/AuditSuccess`, params).then(res => res.data);
};
//审核失败
export const auditFail = params => {
    return axios.post(`${base}/api/DeliverClerkApply/AuditFail`, params).then(res => res.data);
};



//促销列表
export const getPromotionListPage = params => {
    return axios.get(`${base}/api/CoreCmsPromotion/GetPageList`, { params: params });
};

//新增促销信息
export const addPromotion = params => {
    return axios.post(`${base}/api/CoreCmsPromotion/DoCreate`, params).then(res => res.data);
};
//获取促销条件信息
export const getConditionCreate = params => {
    return axios.post(`${base}/api/CoreCmsPromotion/GetConditionCreate`, params).then(res => res.data);
};
//添加促销条件
export const doConditionCreate = params => {
    return axios.post(`${base}/api/CoreCmsPromotion/DoConditionCreate`, params).then(res => res.data);
};
//获取促销条件列表
export const getConditionList = params => {
    return axios.post(`${base}/api/CoreCmsPromotion/GetConditionList`, params).then(res => res.data);
};


//获取促销结果信息
export const getResultCreate = params => {
    return axios.post(`${base}/api/CoreCmsPromotion/GetResultCreate`, params).then(res => res.data);
};

//添加促销结果
export const doResultCreate = params => {
    return axios.post(`${base}/api/CoreCmsPromotion/DoResultCreate`, params).then(res => res.data);
};
//获取促销结果列表
export const getResultList = params => {
    return axios.post(`${base}/api/CoreCmsPromotion/GetResultList`, params).then(res => res.data);
};
//删除促销条件
export const doConditionDelete = params => {
    return axios.post(`${base}/api/CoreCmsPromotion/DoConditionDelete`, params).then(res => res.data);
};

//获取促销信息
export const getPromotion = params => {
    return axios.post(`${base}/api/CoreCmsPromotion/GetEdit`, params).then(res => res.data);
};
//修改促销信息
export const editPromotion = params => {
    return axios.post(`${base}/api/CoreCmsPromotion/DoEdit`, params).then(res => res.data);
};
//删除促销结果
export const doResultDelete = params => {
    return axios.post(`${base}/api/CoreCmsPromotion/DoResultDelete`, params).then(res => res.data);
};

//指定用户发券
export const doGiftCoupon = params => {
    return axios.post(`${base}/api/CoreCmsPromotion/DoGiftCoupon`, params).then(res => res.data);
};


//优惠券列表
export const getCouponListPage = params => {
    return axios.get(`${base}/api/CoreCmsPromotion/GetCouponPageList`, { params: params });
};




//审核通过
export const auditInviteSuccess = params => {
    return axios.post(`${base}/api/StoreInviteApply/DoAuditSuccess`, params).then(res => res.data);
};
//审核失败
export const auditInviteFail = params => {
    return axios.post(`${base}/api/StoreInviteApply/DoAuditFail`, params).then(res => res.data);
};


//售后
export const billAftersalesGetPageList = params => {
    return axios.get(`${base}/api/CoreCmsBillAftersales/GetPageList`, { params: params });
};
export const billAftersalesGetEdit = params => {
    return axios.post(`${base}/api/CoreCmsBillAftersales/GetEdit`, params).then(res => res.data);
};
export const billAftersalesGetDetails = params => {
    return axios.post(`${base}/api/CoreCmsBillAftersales/GetDetails`, params).then(res => res.data);
};
export const billAftersalesDoEdit = params => {
    return axios.post(`${base}/api/CoreCmsBillAftersales/DoEdit`, params).then(res => res.data);
};