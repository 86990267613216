import * as signalR from "@aspnet/signalr";
import { BaseApiUrl } from '/src/api/api';
import { Message } from 'element-ui'
export default {
  SR: {},
  //初始化连接
  initSR: function () {
    let that = this;
    // if (!localStorage.userInfo) return;
    // let userInfo = JSON.parse(localStorage.userInfo);
    // 1.初始化连接暂时不加权限
    that.SR = new signalR.HubConnectionBuilder()
      .withUrl(`${BaseApiUrl}/api/chathub`)
      .configureLogging(signalR.LogLevel.Information)
      .build();
    // that.SR = new signalR.HubConnectionBuilder()
    //   .withUrl("/ChatHub", {
    //     accessTokenFactory: () => encodeURI(userInfo.encryptedAccessToken),
    //   })
    //   .configureLogging(signalR.LogLevel.Information)
    //   .build();
    // 2.携带参数
    // that.SR.qs = {
    //   enc_auth_token: encodeURI(userInfo.encryptedAccessToken),
    // };
    // 3.启动连接的方法
    async function start() {
      try {
        await that.SR.start().then(() => {
          that.SR.on("GlobalNotification", that.alertSR);
          if (that.userName != '')
            that.subscribe();
        });
        console.log("signaR连接成功");
      } catch (err) {
        console.log("err", err);
        setTimeout(start, 5000);
      }
    }
    // 4.关闭之后重连
    that.SR.onclose(async () => {
      await start();
    });
    // 5.启动连接
    start();
  },
  //显示内容
  alertSR: function (data) {
    Message({
      message: data,
      type: "success",
    });
  },
  userName: "",
  subscribe: function () {
    this.SR.invoke("Subscribe", this.userName).catch(function (err) {
      return console.error(err);
    });
  },
  unSubscribe: function () {
    this.SR.invoke("UnSubscribe").catch(function (err) {
      return console.error(err);
    });
  },
  // 停止连接（这个方法好像没啥用，先放着吧）
  stopSR: function () {
    let that = this;
    async function stop() {
      try {
        await that.SR.stop();
        console.log("signaR退出成功");
      } catch (err) {
        console.log(err);
      }
    }
    stop();
  },
};